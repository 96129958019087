const castArray = require('lodash/castArray');

const getTagIETFLanguageCode = require('./getTagIETFLanguageCode');

function sanitizeTagIETF(tagIETF) {
  return tagIETF.replace(/-|_/, '_').toLowerCase();
}

module.exports = function filterPageTranslations(
  pageTranslations,
  browserLocales,
) {
  const sanitizedBrowserLocales = castArray(browserLocales).map(
    sanitizeTagIETF,
  );
  return pageTranslations.filter(
    ({ locale, exist, current }) =>
      !!(exist || current) &&
      (sanitizedBrowserLocales.includes(sanitizeTagIETF(locale.tagIETF)) ||
        sanitizedBrowserLocales.includes(
          getTagIETFLanguageCode(locale.tagIETF),
        ) ||
        sanitizedBrowserLocales.find(
          browserLocale =>
            getTagIETFLanguageCode(browserLocale) ===
            getTagIETFLanguageCode(locale.tagIETF),
        )),
  );
};
