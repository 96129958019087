// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {
    backgroundColor:
      process.env.GATSBY_FOOTER_COLOR ?? theme.palette.common.black,
    color: process.env.GATSBY_FOOTER_CONTRAST_COLOR ?? '#C5C5C6',
    '& .MuiButton-root': {
      minWidth: 'unset',
    },
  },
  wrapper: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingBottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  itemGridContainer: {},
  itemLogoWrapper: {},
  itemLogoWrapperInner: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
  },
  itemContentWrapper: {},
  itemContentWrapperInner: {
    padding: theme.spacing(1),
  },
  itemContentPrimaryWrapper: {},
  itemContentSecondaryWrapper: {},
  itemContentTertiaryWrapper: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'flex-start',
    transform: `translateY(${theme.spacing(-1)}px)`,
  },
  siteLogo: {
    pointerEvents: 'all',
    width: '100%',
    height: 'auto',
    maxWidth: 180,
  },
  footerTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  contactWrapper: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  contactInfoIcon: {
    width: 20,
    overflow: 'visible !important',
    marginRight: theme.spacing(2),
    '& img': {
      objectFit: `contain !important`,
    },
  },
  addressImage: {
    width: '100%',
    height: 105,
    maxWidth: 150,
    minWidth: 150,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      maxWidth: '100%',
    },
  },
  extraLink: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  newsletterDescription: {
    paddingBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: theme.palette.grey.A400,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  bottomFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#C5C5C6',
    [theme.breakpoints.down('sm')]: {
      '& div:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  bottomFooterText: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  copyright: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      '&::after': {
        margin: theme.spacing(0, 1, 0, 1),
        content: JSON.stringify('|'),
      },
    },
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
    },
    '& > *': {
      ...theme.typography.body2,
    },
  },
  actions: {},
  actionLink: {
    color: '#C5C5C6',
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.button.fontFamily,
    textTransform: 'none',
    '&:not(:first-child)': {
      '&::before': {
        content: JSON.stringify('|'),
        margin: theme.spacing(0, 1, 0, 1),
        color: '#C5C5C6',
      },
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  socialLink: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey.A100,
    },
  },
  socialLinks: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: theme.spacing(1),
    },
  },
  extraText: {
    paddingTop: theme.spacing(2),
    color: '#747474',
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
    },
    '& > *': {
      ...theme.typography.caption,
    },
  },
  footerTagline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
    '& svg': {
      fontSize: '6rem',
      height: 'auto',
      color: theme.palette.common.white,
    },
    '& span': {
      ...theme.typography.caption,
    },
  },
  taglineDivider: {
    marginTop: theme.spacing(2),
  },
  pymeWrapperDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: theme.spacing(3),
    },
  },
  pymeWrapperMobile: {
    width: 'auto',
    display: 'block',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  pyme: {
    width: '100px',
    height: '145px',
  },
  federIcon: {
    maxWidth: '200px',
  },
  iso9001Icon: {
    maxWidth: '180px',
  },
  greatPlaceToWorkIcon: {
    maxWidth: '80px',
  },
  extraContactInfo: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  extraContactInfoTitle: {
    color: theme.palette.common.white,
  },
  extraLogos: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
  },
  extraLogo: {},
  extraLogoDescription: {
    marginBottom: theme.spacing(2),
    '& > *': {
      ...theme.typography.caption,
    },
  },
  extraLogoImage: {
    width: '100%',
    height: 'auto',
  },
});

export default styles;
